body, html { overflow-x:hidden!important; }

main {
  margin-top: 3rem;
}

main .container-300 {
  max-width: 1000px;
}

main .card {
  margin-bottom: 2rem;
  max-width: 300px;
}

@media only screen and (max-width: 768px) {
  .read-more {
    text-align: center;
  }
}

.image-overlay {
  display: block;
  position: absolute!important;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.image-overlay-hover {
  display: none;
}

.rowC {
  display: flex;
  flex-direction: row;
}

/* YOUR CUSTOM STYLES */
.app-title {
  font-size: 25px;
  color: white;
  font-weight: bold;
  margin: 5px;
}

.white-color {
  color: white;
}

.btn-cyan-light.dropdown-toggle {
  background-color: #26c6da !important;
  color: white;
}

.btn-cyan-light {
  background-color: #26c6da !important;
  color: #fff;
}

.page-title {
  font-size: 1.8em;
}

.user {
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 10px;
  margin-bottom: 5px;

  object-fit: cover;
}

.read-more {
  text-align: center;
  margin-top: 25px;
  margin-bottom: 25px;
}

html {
  height: 100%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  position: relative;
  margin: 0;
  min-height: 100%;
  font-family: 'Roboto', sans-serif;
}

.card-margin {
  margin-bottom: 25px;
}

.font-0875 {
  font-size: 0.875rem !important;
}

.font-1 {
  font-size: 1rem !important;
}

.font-1-2 {
  font-size: 1.2em !important;
}

.font-1-3 {
  font-size: 1.3em !important;
}

.font-1-4 {
  font-size: 1.4em !important;
}

.cardDisplay-img {
  height: 9rem !important;
  width: 100px;
}

main {
  margin: 0 auto;
  padding-top: 40px;
  max-width: 640px;
  width: 94%;
}

.float-right {
  float: right;
  margin-right: 20px;
  margin-top: 20px;
}

.float-right2 {
  float: right;
  /* margin-right: 20px; */
  margin-top: 22px;
}

.logout {
  margin-bottom: 1rem;
  font-size: 1.8em;
  margin-right: 10px;
}

.invite-friend {
  margin-bottom: 1rem;
  font-size: 2em;
  margin-right: 30px;
}

.friends-nav {
  margin-bottom: 1rem;
  font-size: 2em;
  margin-right: 30px;
}

.float-left {
  float: left;
  margin-left: 20px;
  margin-top: 20px;
}

.friends {
  margin-top: 20px;
  margin-left: 15px;
  margin-right: 15px;
}

.float-center {
  float: center;
  margin-top: 20px;
}

.search-img {
  width: 60%;
  height: 60%;
}

.search-txt {
  margin-top: -25px;
}

.welcome {
  line-height: 1.5em;
}

.logo {
  width: 30%;
}

.logo-login {
  width: 60%;
  margin: 0 auto;
  display: block;
}

/**
 * Footer Styles
 */

footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}

.search-form-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
}

.search-form-container-list {
  width: 100%;
  height: 100%;
  /*display: flex;*/
  justify-content: center;
  align-items: center;
  /*position: absolute;*/
  left: 0;
  top: 0;
}

.swal2-input {
  width: 70% !important;
}

.font-4em {
  font-size: 4em;
}

.font-2em {
  font-size: 2em;
}

.transparent-footer {
  background-color: transparent !important;
  margin-bottom: 10px;
}

.footer-txt {
  color: rgb(145, 143, 143) !important;
}

.modal {
  z-index: 999999;
}

.margin-none {
  margin-left: 0px;
}

.margin-thumbs {
  margin-right: 40px;
}

.review-text {
  margin: auto;
}

.md-form.md-outline {
  margin-left: 10px;
  margin-right: 10px;
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.blue-gradient {
  background-color: #1fc8db;
  background-image: linear-gradient(141deg, #9fb8ad 0%, #1fc8db 51%, #2cb5e8 75%);
}

.purple-gradient {
  background: #A770EF;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #FDB99B, #CF8BF3, #A770EF);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #FDB99B, #CF8BF3, #A770EF);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.light-purple {
  background: #A770EF;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #CF8BF3, #CF8BF3, #CF8BF3);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #CF8BF3, #CF8BF3, #CF8BF3);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.search-form {
  margin-bottom: 20px;
}

.search-margin {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.search-form.movie-list button {
  margin-left: 0px;
}

.font-weight-400 {
  font-weight: 400;
}

.color-blue {
  color: #007bff;
}

.color-red {
  color: red;
}

.card-meta {
  color: #999;
  font-size: 13px;
}

.card-text {
  font-size: 1.2em;
  margin-bottom: 30px!important;
  margin-top: 20px;
}

.search-form.movie-list {
  margin: 0 auto;
  float: none;
}

.margin-auto {
  margin: 0 auto;
  text-align: center;
}

.front {
  z-index: 9999;
  position: relative;
}

.trash {
  font-size: 0.85em;
  margin-top: -10px;
  margin-right: -10px;
  float: right;
  color: gray;
  opacity: 0.7;
}

.bold {
  font-weight: 500;
}

hr {
  margin-top: 0.2rem !important;
  margin-bottom: 0.2rem !important;
}

.search-form .home input,
.search-form .home {
  text-align: center;
}

.pagination-container {
  margin-bottom: 20px;
  display: inline-block !important;
}

.poster-img {
  width: 250px;
  height: 370px;
  margin-left: 5px;
}

.poster-title {
  height: 70px;
  margin-left: 10px;
  margin-top: 10px;
  word-wrap: break-word;
}

.card {
  overflow: hidden;
}

.poster-text {
  margin-left: 10px !important;
  word-wrap: break-word;
}

.center {
  margin: 0 auto;
  width: 100%;
  display: block;
  text-align: center;
}

.color-aqua {
  color: #2bbbad!important;
}

.center-card {
  display: flex;
  align-items: center;
  justify-content: center;
}

.extra-margin {
  margin-left: 35px;
  margin-bottom: 30px;
  margin-top: 10px;
}

.my-swal {
  z-index: 9999999!important;
}

.opacity-button {
  opacity: 0.7;
}

.service-img {
  width: 4%;
  height: 4%;
  margin-right: 7px;
}

.line-watched {
  line-height: 40px;
}

.good {
  color: green;
  font-weight: 500;
}

.bad {
  color: red;
  font-weight: 500;
}

.split {
  color: #654321;
  font-weight: 500;
}

.max-width-100 {
  max-width: 100% !important;
}

/* Progress Bar */
.progress {
  position: fixed;
  top: 0;
  left: 0;
  height: 4px;
  display: block;
  width: 100%;
  background-color: #90caf9;
  border-radius: 2px;
  background-clip: padding-box;
  margin: 0 0 1rem 0;
  overflow: hidden;
}

.margin-toggle {
  margin-top: 30px;
  z-index: 99;
  position: relative;
}

.search-icon {
  font-size: 2.8em;
  margin-bottom: 15px;
}

.search-bottom-btns {
  margin-left: -25px;
}

.search-wrap{
  flex-wrap: wrap;
}

.no-bottom-margin {
  margin-bottom: 10px !important;
}

.blue-color {
  color: blue !important;
}

.inherit-position {
  position: inherit;
  margin-right: 0px;
  margin-left: 0px;
}

.eye {
  width: 3%;
}

.btn-link {
  background: none !important;
  border: none;
  padding: 0 !important;
  /* font-family: arial, sans-serif; */
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}

.margin-toggle-text {
  margin-top: -30px;
}

.progress .determinate {
  position: absolute;
  background-color: inherit;
  top: 0;
  bottom: 0;
  background-color: #4285F4;
  transition: width .3s linear;
}

.progress .indeterminate {
  background-color: #4285F4;
}

.padding-footer {
  padding-top: 100px;
}

.progress .indeterminate:before {
  content: '';
  position: absolute;
  background-color: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  -webkit-animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
  animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}

.progress .indeterminate:after {
  content: '';
  position: absolute;
  background-color: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  -webkit-animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
  animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
  -webkit-animation-delay: 1.15s;
  animation-delay: 1.15s;
}

/*input[type=text], textarea {
  -webkit-transition: all 0.30s ease-in-out;
  -moz-transition: all 0.30s ease-in-out;
  -ms-transition: all 0.30s ease-in-out;
  -o-transition: all 0.30s ease-in-out;
  outline: none;
  padding: 3px 0px 3px 3px;
  margin: 5px 1px 3px 0px;
  border: 1px solid #DDDDDD;
}
 
input[type=text]:focus, textarea:focus {
  box-shadow: 0 0 5px rgba(81, 203, 238, 1);
  padding: 3px 0px 3px 3px;
  margin: 5px 1px 3px 0px;
  border: 1px solid rgba(81, 203, 238, 1);
}*/

.blue-glow {
  border-color: #28a745;
  box-shadow: 0 0 5px rgba(81, 203, 238, 1);
}

.rt {
  width: 5%;
}

.twitter-color {
  color: #1DA1F2;
}

.svgLoader {
  animation: spin 0.7s linear infinite;
  margin: auto;
}

.divLoader {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }

  60% {
    left: 100%;
    right: -90%;
  }

  100% {
    left: 100%;
    right: -90%;
  }
}

@keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }

  60% {
    left: 100%;
    right: -90%;
  }

  100% {
    left: 100%;
    right: -90%;
  }
}

@-webkit-keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }

  60% {
    left: 107%;
    right: -8%;
  }

  100% {
    left: 107%;
    right: -8%;
  }
}

@keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }

  60% {
    left: 107%;
    right: -8%;
  }

  100% {
    left: 107%;
    right: -8%;
  }
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {

  .logout {
    margin-bottom: 1rem;
    font-size: 1.3em;
    margin-right: 10px;
  }
  
  .invite-friend {
    margin-bottom: 1rem;
    font-size: 1.5em;
    margin-right: 20px;
  }
  
  .friends-nav {
    margin-bottom: 1rem;
    font-size: 1.5em;
    margin-right: 20px;
  }

  .welcome {
    font-size: 0.875em;
  }

  .logo {
    width: 100%;
  }

  .service-img {
    width: 15%;
    height: 15%;
    margin-right: 7px;
  }

  .search-txt {
    margin-top: -10px;
  }

  .search-img {
    width: 70%;
    height: 70%;
  }

  .eye {
    width: 10%;
  }

  .search-icon {
    font-size: 2em;
    margin-bottom: 15px;
  }

  .rt {
    width: 10%;
  }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
  .logo {
    width: 80%;
  }
}